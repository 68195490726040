define('heimdall-admin/routes/users/index', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_emberCan.CanMixin, {
    beforeModel: function beforeModel() {
      if (!this.can('list user')) {
        this.transitionTo('index');
      }
    },
    model: function model() {
      return this.store.findAll('user');
    },


    actions: {
      deleteUser: function deleteUser(user) {
        var _this = this;

        var confirmation = confirm('Are you sure you want to delete "' + user.get('username') + '"?');

        if (confirmation) {
          user.destroyRecord().then(function () {
            _this.controller.get('model').removeObject(user);
          });
        }
      }
    }

  });
});