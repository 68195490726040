define('heimdall-admin/services/session', ['exports', 'ember', 'ember-simple-auth/services/session'], function (exports, _ember, _session) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP,
      isEmpty = _ember.default.isEmpty;
  exports.default = _session.default.extend({

    store: _ember.default.inject.service(),

    loadCurrentUser: function loadCurrentUser(include_applications) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        var userId = _this.get('data.authenticated.user.id');
        if (!isEmpty(userId)) {
          if (include_applications) {
            return _this.get('store').findRecord('user', userId, { include: 'applications' }).then(function (user) {
              _this.set('currentUser', user);
              resolve();
            }, reject);
          } else {
            _this.set('currentUser', _this.get('data.authenticated.user'));
            resolve();
          }
        } else {
          resolve();
        }
      });
    }
  });
});