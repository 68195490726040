define('heimdall-admin/routes/services/show', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_emberCan.CanMixin, {
    model: function model(params) {
      return this.store.findRecord('service', params.service_id);
    },
    afterModel: function afterModel(model) {
      if (!this.can('show service', model)) {
        this.transitionTo('index');
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('service', model);
    }
  });
});