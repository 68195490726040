define('heimdall-admin/adapters/application', ['exports', 'ember', 'ember-data', 'heimdall-admin/config/environment'], function (exports, _ember, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  _ember.default.$.ajaxSetup({
    crossDomain: true,
    xhrFields: {
      withCredentials: true
    },
    timeout: 30000
  });

  exports.default = _emberData.default.RESTAdapter.extend({
    host: _environment.default.APP.ENDPOINTS.API,
    headers: {
      'Content-Type': 'application/json'
    },

    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 422 && payload.errors) {
        // Transform errors into json-api
        var errors = [];
        for (var key in payload.errors) {
          var messages = payload.errors[key];
          for (var i = 0; i < messages.length; i++) {
            errors.push({
              detail: messages[i],
              source: {
                pointer: 'data/attributes/' + key
              }
            });
          }
        }
        return new _emberData.default.InvalidError(errors);
      }
      return this._super.apply(this, arguments);
    }
  });
});