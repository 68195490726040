define('heimdall-admin/serializers/application', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var assign = _ember.default.assign || _ember.default.merge;

  exports.default = _emberData.default.RESTSerializer.extend({
    serializeIntoHash: function serializeIntoHash(hash, type, snapshot, options) {
      assign(hash, this.serialize(snapshot, options));
    },
    keyForAttribute: function keyForAttribute(key) {
      return _ember.default.String.decamelize(key);
    }
  });
});