define('heimdall-admin/models/service', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _ember, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    description: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })
  });

  exports.default = _model.default.extend(Validations, {
    name: (0, _attr.default)(),
    description: (0, _attr.default)(),
    public: (0, _attr.default)('boolean-string', { defaultValue: false }),
    migrated: (0, _attr.default)('boolean-string', { defaultValue: false }),
    endpoints: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [];
      } }),
    owners: (0, _relationships.hasMany)('user', { inverse: null }),
    users: (0, _relationships.hasMany)('user'),
    applications: (0, _relationships.hasMany)('applications'),

    applicationIds: _ember.default.computed.mapBy('applications', 'id'),

    ownerUsernames: _ember.default.computed.mapBy('owners', 'username'),

    endpointHosts: _ember.default.computed('endpoints', {
      get: function get() {
        return this.get('endpoints').join("\n");
      },
      set: function set(key, value) {
        var hosts = value.split(/\r?\n/).filter(function (value) {
          return value !== "";
        });
        this.set('endpoints', hosts);
        return value;
      }
    }),

    isOwner: function isOwner(user) {
      return this.hasMany('owners').ids().includes(user.id);
    },
    isUser: function isUser(user) {
      return this.hasMany("users").ids().includes(user.id) || this.isApplicationAssigned(user.get('applications'));
    },
    isPublic: function isPublic() {
      return this.get('public');
    },
    isMigrated: function isMigrated() {
      return this.get('migrated');
    },
    isApplicationAssigned: function isApplicationAssigned(applications) {
      var assigned = false;
      for (var i = 0; i < applications.length; i++) {
        if (this.get('applicationIds').includes(applications[i])) {
          assigned = true;
          break;
        }
      }
      return assigned;
    }
  });
});