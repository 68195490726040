define('heimdall-admin/routes/tokens/index', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_emberCan.CanMixin, {
    beforeModel: function beforeModel() {
      if (!this.can('list token')) {
        this.transitionTo('index');
      }
    },
    model: function model() {
      return this.store.findAll('token');
    }
  });
});