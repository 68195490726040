define('heimdall-admin/routes/users/new', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_emberCan.CanMixin, {
    beforeModel: function beforeModel() {
      if (!this.can('create user')) {
        this.transitionTo('index');
      }
    },
    model: function model() {
      return _ember.default.RSVP.hash({
        user: this.store.createRecord('user'),
        services: this.store.findAll('service')
      });
    },


    actions: {
      saveUser: function saveUser(newUser) {
        var _this = this;

        newUser.save().then(function () {
          return _this.transitionTo('users');
        });
      },
      willTransition: function willTransition() {
        this.controller.get('model').user.rollbackAttributes();
      }
    }

  });
});