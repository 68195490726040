define('heimdall-admin/controllers/endpoints/edit', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({

    actions: {
      saveEndpoint: function saveEndpoint(endpoint) {
        var _this = this;

        if (endpoint.get('isValid')) {
          endpoint.save().then(function () {
            return _this.transitionToRoute('endpoints.show', endpoint.id);
          });
        }
      }
    }

  });
});