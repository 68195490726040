define('heimdall-admin/abilities/service', ['exports', 'ember', 'heimdall-admin/abilities/base'], function (exports, _ember, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    canCreate: _ember.default.computed.alias('user'),

    canList: _ember.default.computed.alias('user'),

    canEdit: _ember.default.computed.alias('_ownerOrAdmin'),

    canAddUsers: _ember.default.computed.alias('_ownerOrAdmin'),

    canCreateWithEndpoint: true,

    canCreateWithPublic: _ember.default.computed.alias('userIsAdmin'),

    canManageEndpoints: _ember.default.computed.alias('_ownerOrAdmin'),

    canShow: _ember.default.computed('model.public', 'model.users', function () {
      return this.get('_ownerOrAdmin') || this.get('model').isPublic() || this.get('model').isUser(this.get('user'));
    }),

    canSeePublicFlag: _ember.default.computed.alias('userIsAdmin'),

    canDelete: _ember.default.computed.alias('_ownerOrAdmin'),

    _ownerOrAdmin: _ember.default.computed('user.role', 'model.owners', function () {
      return this.get('user.admin') || this.get('model').isOwner(this.get('user'));
    })

  });
});