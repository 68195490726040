define("heimdall-admin/controllers/service-users/index", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({

    userColumns: [{
      propertyName: "username",
      title: "Username"
    }, {
      template: "service-users/columns/actions",
      title: "Actions"
    }],

    actions: {
      removeUser: function removeUser(user) {
        var confirmation = confirm("Are you sure you want to remove \"" + user.get('username') + "\" from this service?");

        if (confirmation) {
          var service = this.get('service');
          service.get('users').removeObject(user);
          service.save();
        }
      }
    }
  });
});