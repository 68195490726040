define('heimdall-admin/controllers/services/index', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend(_emberCan.CanMixin, {

    services: _ember.default.computed.filter('model.@each.owners', function (service) {
      return this.can('show service', service);
    }),

    serviceColumns: [{
      propertyName: "name",
      title: "Name"
    }, {
      template: "services/columns/endpoints",
      title: "Endpoints",
      filteredBy: "endpoints"
    }, {
      template: "services/columns/owners",
      title: "Owners",
      filteredBy: 'ownerUsernames',
      sorting: false
    }, {
      template: "services/columns/public",
      title: "Public"
    }, {
      template: "services/columns/migrated",
      title: "Global Protect accesible"
    }, {
      template: "services/columns/actions",
      title: "Actions"
    }]

  });
});