define('heimdall-admin/abilities/user', ['exports', 'ember', 'heimdall-admin/abilities/base'], function (exports, _ember, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    canList: _ember.default.computed.alias('userIsAdmin'),

    canCreate: _ember.default.computed.alias('userIsAdmin'),

    canEdit: _ember.default.computed.alias('userIsAdmin'),

    canDelete: _ember.default.computed.alias('userIsAdmin')

  });
});