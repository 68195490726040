define('heimdall-admin/routes/endpoints/new', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend({
    model: function model() {
      var service = this.modelFor('endpoints'); // get model for parent route
      var verification = this.store.createRecord('verification');

      return _ember.default.RSVP.hash({
        verification: verification,
        endpoint: this.store.createRecord('endpoint', { service: service, verification: verification })
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('endpoint', model.endpoint);
      controller.set('verification', model.verification);
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.get('endpoint').rollbackAttributes();
      }
    }

  });
});