define('heimdall-admin/controllers/index', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({

    session: _ember.default.inject.service('session'),

    serviceColumns: [{
      propertyName: "name",
      title: "Name"
    }, {
      template: "services/columns/owners",
      title: "Owners",
      filteredBy: 'ownerUsernames'
    }],

    vpntokenColumns: [{
      propertyName: "id",
      title: "ID"
    }, {
      propertyName: "created_by_ip",
      title: "IP"
    }, {
      propertyName: "created_by_useragent",
      title: "User-Agent"
    }, {
      propertyName: "created_at",
      title: "Creation Date"
    }, {
      template: "vpntokens/columns/actions",
      title: "Actions"
    }]

  });
});