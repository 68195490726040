define('heimdall-admin/services/network', ['exports', 'ember', 'heimdall-admin/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Service.extend({

    ajax: _ember.default.inject.service('ajax'),

    onHeimdall: false,

    loadStatus: function loadStatus() {
      var _this = this;

      return new _ember.default.RSVP.Promise(function (resolve) {
        var url = _environment.default.APP.ENDPOINTS.API + '/healthcheck';
        return _this.get('ajax').request(url, {
          method: 'GET',
          timeout: 3000
        }).then(function (resp) {
          if (resp.status === "OK") {
            _this.set('onHeimdall', true);
          }
          resolve();
        }).catch(function () {
          return resolve();
        });
      });
    }
  });
});