define('heimdall-admin/components/save-or-cancel', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({

    offset: 2,

    offsetClass: _ember.default.computed('offset', function () {
      return 'col-sm-offset-' + this.get('offset');
    }),

    columnClass: _ember.default.computed('offset', function () {
      return 'col-sm-' + (12 - this.get('offset'));
    })
  });
});