define("heimdall-admin/controllers/vpntokens/index", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({

    vpntokenColumns: [{
      propertyName: "id",
      title: "ID"
    }, {
      propertyName: "user.username",
      title: "Owner"
    }, {
      propertyName: "active",
      title: "Active"
    }, {
      propertyName: "created_by_user",
      title: "Creator"
    }, {
      propertyName: "created_by_ip",
      title: "IP"
    }, {
      propertyName: "created_by_useragent",
      title: "User-Agent"
    }, {
      propertyName: "created_at",
      title: "Creation Date"
    }, {
      propertyName: "updated_at",
      title: "Update Date",
      isHidden: true
    }, {
      template: "vpntokens/columns/actions",
      title: "Actions"
    }]
  });
});