define('heimdall-admin/routes/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_authenticatedRouteMixin.default, {

    can: _ember.default.inject.service('can'),
    session: _ember.default.inject.service('session'),

    beforeModel: function beforeModel() {
      if (!this.get('session.isAuthenticated')) {
        this.transitionTo('login');
      }

      // Unload each record individually from the store to force
      // a refresh when loading this route's model
      // Ref: https://github.com/emberjs/data/issues/5447
      var all = this.store.peekAll('vpntoken');
      all.forEach(function (item) {
        return item.unloadRecord();
      });
    },
    _loadServices: function _loadServices() {
      if (this.get('can').can('reach heimdall endpoints base')) {
        return this.store.findAll('service');
      }
    },
    _loadVpnTokens: function _loadVpnTokens() {
      return this.store.findAll('vpntoken', {
        adapterOptions: {
          userSearch: true
        }
      });
    },
    model: function model() {
      return _ember.default.RSVP.hash({
        services: this._loadServices(),
        vpntokens: this._loadVpnTokens()
      });
    },


    actions: {
      deleteVPNToken: function deleteVPNToken(vpntoken) {
        var confirmation = confirm('Are you sure you want to delete the token "' + vpntoken.get('id') + '"?\n\nThe VPN token will no longer be valid to authenticate against Heimdall.');

        if (confirmation) {
          this.get('store').adapterFor('vpntoken').softDeleteRecord(vpntoken).then(function () {
            vpntoken.set('token', ''); // Forces update of all references of the model
            vpntoken.unloadRecord();
          }).catch(function (error) {
            window.alert('There was an error deleting the token \'' + vpntoken.id + '\'.\n\nStatus: ' + error.errors[0].status + '\nMessage:' + error.errors[0].title);
          });
        }
      }
    }
  });
});