define('heimdall-admin/acceptance-tests/index', ['exports', 'ember-cli-spinjs/acceptance-tests/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _index.default;
    }
  });
});