define('heimdall-admin/routes/services/edit', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_emberCan.CanMixin, {
    model: function model(params) {
      return _ember.default.RSVP.hash({
        users: this.store.findAll('user', { reload: this.get('reloadUsers') }),
        service: this.store.findRecord('service', params.service_id),
        applications: this.store.findAll('application')
      });
    },
    afterModel: function afterModel(model) {
      if (!this.can('edit service', model.service)) {
        this.transitionTo('index');
      }
    },


    actions: {
      willTransition: function willTransition(transition) {
        var model = this.controller.get('model').service;

        if (model.get('hasDirtyAttributes')) {
          var confirmation = confirm("Your changes haven't saved yet. Would you like to leave this form?");

          if (confirmation) {
            model.rollbackAttributes();
          } else {
            transition.abort();
          }
        }
      }
    }
  });
});