define('heimdall-admin/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/application.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/endpoint.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/endpoint.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/service.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/service.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/vpntoken.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/vpntoken.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/application.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/endpoint.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/endpoint.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/service.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/service.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/token.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/token.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/verification.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/verification.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/vpntoken.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/vpntoken.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/service.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/service.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/vpntoken.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/vpntoken.js should pass ESLint\n\n');
  });
});