define('heimdall-admin/controllers/endpoints/new', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({
    session: _ember.default.inject.service(),

    verificationTypes: _ember.default.computed('session.currentUser', function () {
      // let options = ['dns', 'http', 'tcp'];

      // if (this.get('session.currentUser.admin')) {
      //   options.push('manual');
      // }

      return ['manual'];
    }),

    schemeTypes: ['http', 'https'],

    actions: {
      saveEndpoint: function saveEndpoint(newEndpoint) {
        var _this = this;

        if (newEndpoint.get('isValid')) {
          newEndpoint.save().then(function () {
            return _this.transitionToRoute('endpoints.show', newEndpoint.id);
          });
        }
      },
      setVerificationType: function setVerificationType(type) {
        this.get('verification').setProperties({
          'type': type,
          'parameters': {}
        });
      }
    }

  });
});