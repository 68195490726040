define("heimdall-admin/mirage/serializers/service", ["exports", "heimdall-admin/mirage/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize(object, request) {
      var _this = this;

      var json = void 0;

      if (this.isModel(object)) {
        json = {
          "service": this._serialize(object, request)
        };
      } else {
        var res = object.models.map(function (object) {
          return _this._serialize(object, request);
        });
        json = {
          "services": res
        };
      }

      return json;
    },
    _serialize: function _serialize(object, request) {
      var json = _application.default.prototype.serialize.apply(this, [object, request])['service'];
      json['owners'] = object.owners.models.map(function (e) {
        return e.id;
      });
      json['users'] = object.users.models.map(function (e) {
        return e.id;
      });
      return json;
    }
  });
});