define('heimdall-admin/serializers/port-range', ['exports', 'ember', 'ember-data/serializers/json'], function (exports, _ember, _json) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _json.default.extend({
    keyForAttribute: function keyForAttribute(key) {
      return _ember.default.String.decamelize(key);
    }
  });
});