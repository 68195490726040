define('heimdall-admin/components/service-form', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    actions: {
      saveService: function saveService(service) {
        if (service.get('isValid')) {
          this.sendAction('save', service);
        }
      }
    }
  });
});