define('heimdall-admin/routes/users/edit', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_emberCan.CanMixin, {
    model: function model(params) {
      return _ember.default.RSVP.hash({
        user: this.store.findRecord('user', params.user_id),
        services: this.store.findAll('service')
      });
    },
    afterModel: function afterModel(model) {
      if (!this.can('edit user', model.user)) {
        this.transitionTo('index');
      }
    },


    actions: {
      saveUser: function saveUser(user) {
        var _this = this;

        user.save().then(function () {
          return _this.transitionTo('users');
        });
      },
      willTransition: function willTransition(transition) {

        var model = this.controller.get('model').user;

        if (model.get('hasDirtyAttributes')) {
          var confirmation = confirm("Your changes haven't saved yet. Would you like to leave this form?");

          if (confirmation) {
            model.rollbackAttributes();
          } else {
            transition.abort();
          }
        }
      }
    }

  });
});