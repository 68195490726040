define('heimdall-admin/abilities/base', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCan.Ability.extend({

    session: _ember.default.inject.service('session'),
    network: _ember.default.inject.service('network'),

    user: _ember.default.computed.alias('session.currentUser'),

    userIsAdmin: _ember.default.computed('user.role', function () {
      return this.get('user.admin');
    }),

    canReachHeimdallEndpoints: _ember.default.computed.alias('network.onHeimdall')

  });
});