define('heimdall-admin/serializers/service', ['exports', 'heimdall-admin/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      endpoints: { embedded: 'always' }
    },

    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload /*, id, requestType*/) {
      payload['service'] = { 'id': payload.service_id };
      delete payload.service_id;
      return this._super.apply(this, arguments);
    }
  });
});