define('heimdall-admin/routes/service-users/index', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend({
    setupController: function setupController(controller, model) {
      controller.set('service', model);
    }
  });
});