define('heimdall-admin/components/port-ranges-form', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    protocols: ['tcp', 'udp', 'icmp'],

    actions: {
      addPortRange: function addPortRange() {
        this.get('endpoint.portRanges').pushObject({});
      },
      removePortRange: function removePortRange(portRange) {
        this.get('endpoint.portRanges').removeObject(portRange);
      }
    }
  });
});