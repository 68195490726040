define('heimdall-admin/components/cancel-link', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    tagName: 'span',

    actions: {
      back: function back() {
        history.back();
      }
    }
  });
});