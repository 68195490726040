define('heimdall-admin/components/basic-table', ['exports', 'ember-models-table/components/models-table'], function (exports, _modelsTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modelsTable.default.extend({
    useFilteringByColumns: false,
    showColumnsDropdown: false,
    useNumericPagination: true,
    multipleColumnsSorting: false,
    filteringIgnoreCase: true,
    layoutName: 'components/models-table',
    customClasses: {
      table: 'basic-table table table-striped table-bordered table-condensed'
    }
  });
});