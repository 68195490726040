define("heimdall-admin/controllers/endpoints/index", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({

    endpointColumns: [{
      propertyName: "host",
      title: "Endpoint"
    }, {
      propertyName: "verification.type",
      title: "Verification Type"
    }, {
      propertyName: "verification.status",
      title: "Verification Status"
    }, {
      template: "endpoints/columns/actions",
      title: "Actions"
    }]

  });
});