define('heimdall-admin/routes/endpoints/show', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend({
    model: function model(params) {
      return this.store.findRecord('endpoint', params.endpoint_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('endpoint', model);
    },


    actions: {
      deleteEndpoint: function deleteEndpoint(endpoint) {
        var _this = this;

        var confirmation = confirm('Are you sure you want to delete "' + endpoint.get('host') + '"?');

        if (confirmation) {
          endpoint.destroyRecord().then(function () {
            var service = _this.modelFor('endpoints');
            _this.transitionTo('endpoints', service.id);
          });
        }
      }
    }

  });
});