define('heimdall-admin/mirage/factories/service', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: function name(i) {
      return 'Service ' + i;
    },


    description: _emberCliMirage.faker.lorem.sentence,

    public: false,

    migrated: false

  });
});