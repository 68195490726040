define('heimdall-admin/routes/endpoints/index', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend({
    model: function model() {
      var service = this._super.apply(this, arguments);
      return this.store.query('endpoint', { service_id: service.get('id') });
    },


    actions: {
      deleteEndpoint: function deleteEndpoint(endpoint) {
        var confirmation = confirm('Are you sure you want to delete "' + endpoint.get('host') + '"?');

        if (confirmation) {
          endpoint.destroyRecord();
        }
      }
    }

  });
});