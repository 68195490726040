define('heimdall-admin/components/user-form', ['exports', 'ember', 'ember-power-select/utils/group-utils'], function (exports, _ember, _groupUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    servicesMatcher: function servicesMatcher(service, term) {
      return (0, _groupUtils.defaultMatcher)(service.get('name') + ' ' + service.get('description'), term);
    },


    roles: ['admin', 'user'],

    actions: {
      saveUser: function saveUser(user) {
        if (user.get('isValid')) {
          this.sendAction('save', user);
        }
      }
    }
  });
});