define('heimdall-admin/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'heimdall-admin/config/environment'], function (exports, _ember, _applicationRouteMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_applicationRouteMixin.default, {

    session: _ember.default.inject.service('session'),
    network: _ember.default.inject.service('network'),

    _handleAuthentication: function _handleAuthentication() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        return this._loadCurrentUser();
      } else {
        return this.get('session').authenticate('authenticator:okta').then(function () {
          return _this._loadCurrentUser();
        }).catch(function () {
          _this.transitionTo('login');
        });
      }
    },
    beforeModel: function beforeModel() {
      var _this2 = this;

      return _ember.default.RSVP.hash({
        session: this._handleAuthentication().then(function () {
          // If authentication succeded, preload all users to prevent N single user requests
          if (_this2.get('session.isAuthenticated')) {
            _this2.get('store').findAll('user');
          }
        }),
        network: this.get('network').loadStatus()
      });
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var _this3 = this;

      this._super.apply(this, arguments);
      this._loadCurrentUser().catch(function () {
        return _this3.get('session').invalidate();
      });
    },
    sessionInvalidated: function sessionInvalidated() {
      window.location.replace(_environment.default.APP.ENDPOINTS.LOGOUT);
    },
    _loadCurrentUser: function _loadCurrentUser() {
      return this.get('session').loadCurrentUser();
    },


    actions: {
      loading: function loading(transition) {
        _ember.default.$('#global-spinner').show();
        transition.promise.finally(function () {
          _ember.default.$('#global-spinner').hide();
        });
        return true;
      }
    }
  });
});