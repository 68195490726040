define('heimdall-admin/helpers/to-json', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toJSON = toJSON;
  function toJSON(params) {
    return JSON.stringify(params);
  }

  exports.default = _ember.default.Helper.helper(toJSON);
});