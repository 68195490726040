define('heimdall-admin/mirage/serializers/vpntoken', ['exports', 'heimdall-admin/mirage/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return attr;
    }
  });
});