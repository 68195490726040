define('heimdall-admin/adapters/vpntoken', ['exports', 'heimdall-admin/adapters/application', 'heimdall-admin/config/environment', 'ember'], function (exports, _application, _environment, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    session: _ember.default.inject.service(),

    getBaseURL: function getBaseURL(modelName) {
      return _environment.default.APP.ENDPOINTS.API + '/' + this.pathForType(modelName);
    },


    // Override methods for the URL generation
    // Ref: https://api.emberjs.com/ember-data/2.14/classes/DS.RESTAdapter 
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      if (snapshot && snapshot.adapterOptions && snapshot.adapterOptions.userSearch === true) {
        var base_url = this.getBaseURL(modelName);
        var user_id = this.get('session.currentUser.id');
        return base_url + '?user_id=' + user_id;
      }
      return this._super.apply(this, arguments);
    },


    // softDeleteRecord is used to delete a vpn token from the backend, without
    // removing it from the Ember store.
    // This workaround is needed because the backend API implementation does
    // not actually delete vpntokens, but marks them as 'inactive'. This breaks 
    // the REST API "standard", and conflicts with ember-data workflow.
    softDeleteRecord: function softDeleteRecord(vpntoken) {
      var base_url = this.getBaseURL('vpntoken');
      return this.ajax(base_url + '/' + vpntoken.id, 'DELETE');
    }
  });
});