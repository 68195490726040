define('heimdall-admin/models/application', ['exports', 'ember', 'ember-data/model', 'ember-data/attr'], function (exports, _ember, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)(),
    label: (0, _attr.default)(),
    labelName: _ember.default.computed('label', 'name', function () {
      if (this.get('name')) {
        return this.get('label') + ' (' + this.get('name') + ')';
      } else {
        return "";
      }
    })
  });
});