define('heimdall-admin/serializers/user', ['exports', 'heimdall-admin/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload /*, id, requestType*/) {
      payload['user'] = { 'id': payload.user_id };
      delete payload.user_id;
      return this._super.apply(this, arguments);
    }
  });
});