define('heimdall-admin/router', ['exports', 'ember', 'heimdall-admin/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = _ember.default.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('services', function () {
      this.route('new');
      this.route('edit', { path: '/:service_id/edit' });
      this.route('show', { path: '/:service_id' });
    });
    this.route('endpoints', { path: '/services/:service_id/endpoints' }, function () {
      this.route('new');
      this.route('edit', { path: '/:endpoint_id/edit' });
      this.route('show', { path: '/:endpoint_id' });
    });
    this.route('service-users', { path: '/services/:service_id/users' }, function () {
      this.route('add');
    });
    this.route('users', function () {
      this.route('new');
      this.route('edit', { path: '/:user_id' });
    });
    this.route('tokens', function () {
      this.route('new');
      this.route('edit', { path: '/:token_id/edit' });
    });
    this.route('routing');
    this.route('vpntokens', function () {
      this.route('new');
    });
  });

  exports.default = Router;
});