define('heimdall-admin/models/user', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _ember, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    username: (0, _emberCpValidations.validator)('format', {
      type: 'email'
    }),
    role: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })
  });

  exports.default = _model.default.extend(Validations, {
    username: (0, _attr.default)(),
    role: (0, _attr.default)(),
    services: (0, _relationships.hasMany)('services', { inverse: 'users' }),
    applications: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    disabled: (0, _attr.default)('boolean-string', { defaultValue: false }),

    admin: _ember.default.computed('role', function () {
      return this.get('role') === 'admin';
    }),

    isDisabled: function isDisabled() {
      return this.get('disabled');
    }
  });
});