define('heimdall-admin/components/vpntoken-creator', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    store: _ember.default.inject.service('store'),
    session: _ember.default.inject.service('session'),
    newtoken: null,
    actions: {
      clipboardCheck: function clipboardCheck() {
        _ember.default.$('#clipboard').attr('style', 'display: none');
        _ember.default.$('#clipboard-check').attr('style', 'display: ');
      },
      clipboardUncheck: function clipboardUncheck() {
        _ember.default.$('#clipboard').attr('style', 'display: ');
        _ember.default.$('#clipboard-check').attr('style', 'display: none');
      },
      setToken: function setToken(vpntoken) {
        this.set('newtoken', vpntoken);
        this.send('clipboardUncheck');
      },
      toClipboard: function toClipboard() {
        var _this = this;

        navigator.clipboard.writeText(this.get('newtoken.token')).then(function () {
          _this.send('clipboardCheck');
        }).catch(function () {
          alert('Error while copying the secret vpntoken to the device\'s clipboard.\nYou should copy the secret manually.');
        });
      },
      newVPNToken: function newVPNToken() {
        var _this2 = this;

        this.get('store').createRecord('vpntoken', {
          user: this.get('session.currentUser')
        }).save().then(function (record) {
          record.reload();
          _this2.send('setToken', record);
        }, function (error) {
          window.alert('There was an error in the vpntoken generation.\n\nStatus: ' + error.errors[0].status + '\nMessage: ' + error.errors[0].title);
        });
      }
    }
  });
});