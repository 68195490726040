define('heimdall-admin/components/login-button', ['exports', 'ember', 'heimdall-admin/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({

    session: _ember.default.inject.service('session'),

    actions: {
      authenticate: function authenticate() {
        this.get('session').authenticate('authenticator:okta').catch(function () {
          window.location.replace(_environment.default.APP.ENDPOINTS.LOGIN);
        });
      },
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }

  });
});