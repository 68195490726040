define("heimdall-admin/mirage/factories/vpntoken", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({

    date: new Date(),

    id: function id() {
      return Math.random().toString(36).substring(2, 10);
    },
    active: function active() {
      return true;
    },
    created_at: function created_at() {
      return this.date.toISOString();
    },
    updated_at: function updated_at() {
      return this.date.toISOString();
    },
    created_by: function created_by() {
      var ip = _emberCliMirage.faker.internet.ip();
      var useragent = _emberCliMirage.faker.internet.userAgent();
      return "creator=" + this.creator + ", ip=" + ip + ", user-agent=" + useragent;
    },
    user: function user() {
      return "1";
    },
    creator: function creator() {
      var user = server.schema.users.find(this.user);
      if (user) {
        return user.username || "";
      }
      return _emberCliMirage.faker.internet.email();
    }
  });
});