define('heimdall-admin/routes/vpntokens/index', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_emberCan.CanMixin, {
    beforeModel: function beforeModel() {
      if (!this.can('list all users vpntoken')) {
        this.transitionTo('index');
      }
    },
    model: function model() {
      return this.get('store').query('vpntoken', { include_inactive: true });
    },


    actions: {
      deleteVPNToken: function deleteVPNToken(vpntoken) {
        var confirmation = confirm('Are you sure you want to delete the token "' + vpntoken.get('id') + '"?\n\nThe VPN token will no longer be valid to authenticate against Heimdall.');

        if (confirmation) {
          this.get('store').adapterFor('vpntoken').softDeleteRecord(vpntoken).then(function () {
            return vpntoken.set('active', false);
          }).catch(function (error) {
            window.alert('There was an error deleting the token \'' + vpntoken.id + '\'.\n\nStatus: ' + error.errors[0].status + '\nMessage:' + error.errors[0].title);
          });
        }
      }
    }
  });
});