define('heimdall-admin/controllers/tokens/edit', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({
    actions: {
      saveToken: function saveToken(token) {
        var _this = this;

        token.save().then(function () {
          return _this.transitionToRoute('tokens.index');
        });
      }
    }
  });
});