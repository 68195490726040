define('heimdall-admin/models/endpoint', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'ember-data-model-fragments/attributes'], function (exports, _model, _attr, _relationships, _emberCpValidations, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var IP_REGEX = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;

  var Validations = (0, _emberCpValidations.buildValidations)({
    host: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)(function (value, options, model) {
      if (value.match(IP_REGEX) && model.get('verification.type') === 'dns') {
        return "Endpoint can't be an IP address when verification type is DNS";
      }
      return true;
    })],
    verification: (0, _emberCpValidations.validator)('belongs-to')
  });

  exports.default = _model.default.extend(Validations, {
    service: (0, _relationships.belongsTo)('service'),
    host: (0, _attr.default)(),
    verification: (0, _relationships.belongsTo)('verification'),
    portRanges: (0, _attributes.fragmentArray)('port-range')
  });
});