define('heimdall-admin/routes/tokens/edit', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_emberCan.CanMixin, {
    model: function model(params) {
      return this.store.findRecord('token', params.token_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('token', model);
    },
    afterModel: function afterModel(model) {
      if (!this.can('edit token', model)) {
        this.transitionTo('index');
      }
    },


    actions: {
      willTransition: function willTransition(transition) {

        var model = this.controller.get('model');

        if (model.get('hasDirtyAttributes')) {
          var confirmation = confirm("Your changes haven't saved yet. Would you like to leave this form?");

          if (confirmation) {
            model.rollbackAttributes();
          } else {
            transition.abort();
          }
        }
      }
    }
  });
});