define('heimdall-admin/routes/endpoints/edit', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend({
    model: function model(params) {
      return this.store.findRecord('endpoint', params.endpoint_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('endpoint', model);
    },


    actions: {
      willTransition: function willTransition(transition) {

        var endpoint = this.controller.get('endpoint');

        if (endpoint.get('hasDirtyAttributes')) {
          var confirmation = confirm("Your changes haven't saved yet. Would you like to leave this form?");

          if (confirmation) {
            endpoint.rollbackAttributes();
          } else {
            transition.abort();
          }
        }
      }
    }

  });
});