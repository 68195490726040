define('heimdall-admin/routes/service-users/add', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_emberCan.CanMixin, {
    model: function model() {
      var service = this.modelFor('service-users');
      return _ember.default.RSVP.hash({
        users: this.store.findAll('user'),
        service: service
      });
    },
    afterModel: function afterModel(model) {
      if (!this.can('add users to service', model.service)) {
        this.transitionTo('index');
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('service', model.service);
      controller.set('users', model.users);
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);
      controller.set('users', null);
      controller.set('assignedUsers', null);
    }
  });
});