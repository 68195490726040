define('heimdall-admin/initializers/model-fragments', ['exports', 'ember-data-model-fragments/transforms/fragment', 'ember-data-model-fragments/transforms/fragment-array', 'ember-data-model-fragments/transforms/array', 'ember-data-model-fragments'], function (exports, _fragment, _fragmentArray, _array) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'fragmentTransform',
    before: 'ember-data',

    initialize: function initialize(application) {
      application.register('transform:fragment', _fragment.default);
      application.register('transform:fragment-array', _fragmentArray.default);
      application.register('transform:array', _array.default);
    }
  };
});