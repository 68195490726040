define('heimdall-admin/controllers/service-users/add', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({
    unassignedUsers: _ember.default.computed.filter('users', function (user) {
      return !this.get('service').isUser(user);
    }),

    assignedUsers: _ember.default.A(),

    actions: {
      saveService: function saveService(service) {
        var _this = this;

        service.get('users').pushObjects(this.get('assignedUsers'));
        service.save().then(function () {
          _this.transitionToRoute('service-users.index', service.get('id'));
        });
      }
    }
  });
});