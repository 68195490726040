define('heimdall-admin/routes/tokens/new', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_emberCan.CanMixin, {
    beforeModel: function beforeModel() {
      if (!this.can('create token')) {
        this.transition.to('index');
      }
    },
    model: function model() {
      return _ember.default.RSVP.hash({
        token: this.store.createRecord('token'),
        users: this.store.findAll('user')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('users', model.users);
      controller.set('token', model.token);
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.get('model').token.rollbackAttributes();
      }
    }

  });
});