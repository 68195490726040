define('heimdall-admin/components/bs-form-element', ['exports', 'ember-bootstrap-cp-validations/components/bs-form-element'], function (exports, _bsFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bsFormElement.default;
    }
  });
});