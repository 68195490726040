define('heimdall-admin/routes/vpntokens/new', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_emberCan.CanMixin, {
    model: function model() {
      return _ember.default.RSVP.hash({
        vpntoken: this.store.createRecord('vpntoken'),
        users: this.store.findAll('user')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('vpntoken', model.vpntoken);
      controller.set('users', model.users);
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.get('model').vpntoken.rollbackAttributes();
      }
    }

  });
});