define("heimdall-admin/controllers/tokens/index", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({

    tokenColumns: [{
      propertyName: "name",
      title: "Name"
    }, {
      propertyName: "description",
      title: "Description"
    }, {
      propertyName: "user.username",
      title: "User"
    }, {
      propertyName: "id",
      title: "Token"
    }, {
      template: "tokens/columns/actions",
      title: "Actions"
    }],

    actions: {
      deleteToken: function deleteToken(token) {
        var _this = this;

        var confirmation = confirm("Are you sure you want to delete \"" + token.get('name') + "\"?");

        if (confirmation) {
          token.destroyRecord().then(function () {
            _this.get('model').removeObject(token);
          });
        }
      }
    }
  });
});