define('heimdall-admin/mirage/config', ['exports', 'ember-cli-mirage', 'heimdall-admin/config/environment'], function (exports, _emberCliMirage, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {

    this.urlPrefix = _environment.default.APP.ENDPOINTS.API;

    this.get('/users', function (_ref) {
      var users = _ref.users;

      return users.all();
    });

    this.post('/users', function (_ref2, request) {
      var users = _ref2.users;

      var params = JSON.parse(request.requestBody);

      var user = users.create(params);

      return { user_id: user.id };
    });

    this.put('/users/:user_id', function (_ref3, request) {
      var users = _ref3.users;

      var params = JSON.parse(request.requestBody);

      return users.find(request.params.user_id).update(params);
    });

    this.delete('/users/:user_id', function (schema, request) {
      return schema.db.users.remove(request.params.user_id);
    });

    this.get('/users/current', function (_ref4) {
      var users = _ref4.users;

      var user = users.where({ current: true }).models[0];

      if (user) {
        return user;
      } else {
        return new _emberCliMirage.Response(403);
      }
    });

    this.get('/users/:user_id', function (_ref5, request) {
      var users = _ref5.users;

      return users.find(request.params.user_id);
    });

    this.get('/services', function (_ref6) {
      var services = _ref6.services;

      return services.all();
    });

    this.post('/services', function (_ref7, request) {
      var services = _ref7.services,
          endpoints = _ref7.endpoints,
          verifications = _ref7.verifications;

      var params = JSON.parse(request.requestBody);

      // Transform owners into ownerIds so ORM works
      params.ownerIds = params.owners;
      delete params.owners;

      var endpointParams = params.endpoints;
      delete params.endpoints;

      var service = services.create(params);

      endpointParams.forEach(function (endpoint) {
        var verification = verifications.create({
          type: 'manual',
          status: 'ok'
        });

        endpoints.create({
          service_id: service.id,
          host: endpoint,
          verification: verification
        });
      });

      return { service_id: service.id };
    });

    this.get('/services/:service_id', function (_ref8, request) {
      var services = _ref8.services,
          endpoints = _ref8.endpoints;

      var serviceId = request.params.service_id;
      var service = services.find(serviceId);
      var hosts = endpoints.where({ service_id: serviceId }).models.map(function (e) {
        return e.host;
      });

      var response = this.serialize(service);
      response['service']['endpoints'] = hosts;

      return response;
    });

    this.put('/services/:service_id', function (_ref9, request) {
      var services = _ref9.services,
          verifications = _ref9.verifications,
          endpoints = _ref9.endpoints;

      var params = JSON.parse(request.requestBody);
      var serviceId = request.params.service_id;

      var endpointParams = params.endpoints;
      delete params.endpoints;

      endpoints.where({ service_id: serviceId }).destroy();

      endpointParams.forEach(function (endpoint) {
        var verification = verifications.create({
          type: 'manual',
          status: 'ok'
        });

        endpoints.create({
          service_id: serviceId,
          host: endpoint,
          verification: verification
        });
      });

      delete params.owners;
      delete params.users;

      services.find(serviceId).update(params);

      return;
    });

    this.delete('/services/:service_id', function (schema, request) {
      return schema.db.services.remove(request.params.service_id);
    });

    this.get('/endpoints', function (_ref10, request) {
      var endpoints = _ref10.endpoints;

      return endpoints = endpoints.where({ service_id: request.queryParams.service_id });
    });

    this.get('/endpoints/:endpoint_id', function (_ref11, request) {
      var endpoints = _ref11.endpoints;

      return endpoints.find(request.params.endpoint_id);
    });

    this.post('/endpoints', function (_ref12, request) {
      var endpoints = _ref12.endpoints,
          verifications = _ref12.verifications;

      var params = JSON.parse(request.requestBody);

      var verification_params = params.verification;

      var verification = verifications.create({
        type: verification_params.type,
        parameters: verification_params.parameters,
        status: 'PENDING'
      });

      var endpoint = endpoints.create({
        service_id: params.service_id,
        host: params.host,
        verification: verification
      });

      return { endpoint_id: endpoint.id };
    });

    this.delete('/endpoints/:endpoint_id', function (schema, request) {
      return schema.db.endpoints.remove(request.params.endpoint_id);
    });

    this.get('/tokens', function (_ref13) {
      var tokens = _ref13.tokens;

      return tokens.all();
    });

    this.post('/tokens', function (_ref14, request) {
      var tokens = _ref14.tokens;

      var params = JSON.parse(request.requestBody);

      var token = tokens.create(params);

      return { token_id: token.id };
    });

    this.put('/tokens/:token_id', function (_ref15, request) {
      var tokens = _ref15.tokens;

      var params = JSON.parse(request.requestBody);

      return tokens.find(request.params.token_id).update(params);
    });

    this.delete('/tokens/:token_id', function (schema, request) {
      return schema.db.tokens.remove(request.params.token_id);
    });

    this.get('/tokens/:token_id', function (_ref16, request) {
      var tokens = _ref16.tokens;

      return tokens.find(request.params.token_id);
    });

    this.get('/applications', function (_ref17) {
      var applications = _ref17.applications;

      return applications.all();
    });

    this.get('/vpntokens', function (schema, request) {
      var user_id = request.queryParams.user_id;
      var include_inactive = request.queryParams.include_inactive || false;

      var tokens = schema.vpntokens.all();

      if (user_id !== undefined) {
        tokens = tokens.filter(function (token) {
          return token.user === user_id;
        });
      }

      if (!include_inactive) {
        tokens = tokens.filter(function (token) {
          return token.active === true;
        });
      }

      return tokens;
    });

    this.post('vpntokens', function (schema, request) {
      var user_id = JSON.parse(request.requestBody).user_id;
      var vpntoken = server.create('vpntoken', { user: user_id });
      var token = function token() {
        var rand = new Uint8Array(8);
        crypto.getRandomValues(rand);
        return btoa(rand);
      };

      return { id: vpntoken.id, token: 'heim.' + vpntoken.id + '.' + token() };
    });

    this.get('/vpntokens/:id', function (schema, request) {
      return schema.vpntokens.find(request.params.id);
    });

    this.delete('/vpntokens/:id', function (schema, request) {
      var record = schema.vpntokens.find(request.params.id);
      record.destroy();
    });

    this.get('/healthcheck', function () {
      return {
        status: "OK"
      };
    });
  };
});