define("heimdall-admin/controllers/routing", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({

    endpointColumns: [{
      propertyName: "host",
      title: "Endpoint"
    }, {
      template: "routing/columns/port-ranges",
      title: "Port Ranges"
    }]

  });
});