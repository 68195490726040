define('heimdall-admin/controllers/vpntokens/new', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({
    actions: {
      saveVPNToken: function saveVPNToken(newVPNToken) {
        var self = this;
        newVPNToken.save().then(function (record) {
          self.set('vpntoken', self.store.createRecord('vpntoken', {
            token: record.get('token'),
            user: record.get('user')
          }));
        });
      }
    }
  });
});