define('heimdall-admin/routes/services/new', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_emberCan.CanMixin, {

    session: _ember.default.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.can('create service')) {
        this.transition.to('index');
      }
    },
    model: function model() {
      var owners = this.get('session.currentUser.admin') ? [] : [this.get('session.currentUser')];
      return _ember.default.RSVP.hash({
        service: this.store.createRecord('service', { owners: owners }),
        users: this.store.findAll('user'),
        applications: this.store.findAll('application')
      });
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.get('model').service.rollbackAttributes();
      }
    }

  });
});