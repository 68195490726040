define('heimdall-admin/adapters/user', ['exports', 'ember', 'heimdall-admin/adapters/application', 'heimdall-admin/config/environment'], function (exports, _ember, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    current: function current() {
      var _this = this;

      return new _ember.default.RSVP.Promise(function (resolve, reject) {
        return _this.ajax(_environment.default.APP.ENDPOINTS.CURRENT_USER, 'GET', {
          timeout: 2000
        }).then(function (response) {
          resolve(_this.store.push(_this.store.normalize('user', response.user)));
        }).catch(function () {
          return reject();
        });
      });
    },
    routing: function routing(username) {
      return this.ajax(_environment.default.APP.ENDPOINTS.API + '/users/name/' + username + '/routing', 'GET', {
        timeout: 5000
      });
    }
  });
});