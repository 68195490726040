define('heimdall-admin/serializers/vpntoken', ['exports', 'heimdall-admin/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload /*, id, requestType*/) {
      payload['vpntoken'] = {
        'id': payload.id,
        'token': payload.token
      };
      delete payload.id;
      delete payload.token;
      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot) {
      return {
        user_id: parseInt(snapshot.record.get('user.id'))
      };
    }
  });
});