define('heimdall-admin/mirage/factories/endpoint', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    host: function host() {
      return _emberCliMirage.faker.internet.domainName();
    }
  });
});