define('heimdall-admin/models/port-range', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-cp-validations'], function (exports, _emberData, _emberDataModelFragments, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    protocol: [(0, _emberCpValidations.validator)('presence', true)],
    fromPort: [(0, _emberCpValidations.validator)(function (value, options, model) {
      if (model.get('protocol') == "icmp") {
        if (value) return "From Port is not allowed for ICMP";
      } else {
        if (!value) return "From Port is required for TCP and UDP protocols";
      }
      return true;
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      allowBlank: true,
      integer: true,
      gte: 0,
      lte: 65535
    })],
    toPort: [(0, _emberCpValidations.validator)(function (value, options, model) {
      if (value && model.get('protocol') == "icmp") {
        return "To Port is not allowed for ICMP";
      }
      return true;
    }), (0, _emberCpValidations.validator)(function (value, options, model) {
      if (value && !model.get('fromPort')) {
        return "To Port must be blank if From Port is blank";
      }
      return true;
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      allowBlank: true,
      integer: true,
      gt: 0,
      lte: 65535
    }), (0, _emberCpValidations.validator)(function (value, options, model) {
      if (value && value <= model.get('fromPort')) {
        return "To Port must be greater than From Port";
      }
      return true;
    })]
  });

  exports.default = _emberDataModelFragments.default.Fragment.extend(Validations, {
    protocol: _emberData.default.attr('string'),
    fromPort: _emberData.default.attr('number'),
    toPort: _emberData.default.attr('number')
  });
});