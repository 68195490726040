define('heimdall-admin/routes/routing', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = _ember.default.Object;
  exports.default = _ember.default.Route.extend(_authenticatedRouteMixin.default, {

    session: _ember.default.inject.service(),

    model: function model() {
      return this.get('store').adapterFor('user').routing(this.get('session.currentUser.username')).then(function (response) {
        return response.endpoints.map(function (endpoint) {
          return EmberObject.create(endpoint);
        });
      });
    }
  });
});