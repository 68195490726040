define('heimdall-admin/authenticators/okta', ['exports', 'ember', 'ember-simple-auth/authenticators/base'], function (exports, _ember, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP,
      isEmpty = _ember.default.isEmpty;
  exports.default = _base.default.extend({

    store: _ember.default.inject.service(),

    restore: function restore(data) {
      if (isEmpty(data)) {
        return RSVP.reject();
      } else {
        return this._authenticate();
      }
    },
    authenticate: function authenticate() {
      return this._authenticate();
    },
    _authenticate: function _authenticate() {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        _this.get('store').adapterFor('user').current().then(function (user) {
          _ember.default.run(function () {
            resolve({ user: user });
          });
        }).catch(function () {
          _ember.default.run(function () {
            reject();
          });
        });
      });
    }
  });
});