define('heimdall-admin/routes/services/index', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_emberCan.CanMixin, {

    session: _ember.default.inject.service('session'),

    beforeModel: function beforeModel() {
      if (!this.can('list service')) {
        this.transitionTo('index');
      }
      // If the currentUser object in the store doesn't contain information
      // about Okta application, make sure to load it
      if (_ember.default.isEmpty(this.get('session.currentUser.applications'))) {
        return this.get('session').loadCurrentUser(true);
      }
    },
    model: function model() {
      return this.store.findAll('service');
    },


    actions: {
      deleteService: function deleteService(service) {
        var _this = this;

        var confirmation = confirm('Are you sure you want to delete "' + service.get('name') + '"?');

        if (confirmation) {
          service.destroyRecord().then(function () {
            _this.controller.get('model').removeObject(service);
          });
        }
      }
    }

  });
});