define('heimdall-admin/models/verification', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _ember, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var IP_REGEX = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;

  var Validations = (0, _emberCpValidations.buildValidations)({
    type: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        var host = model.get('endpoint.host');
        if (value === 'dns' && host && host.match(IP_REGEX)) {
          return "Type can't be dns when host is an IP";
        } else {
          return true;
        }
      })]
    },
    'parameters.scheme': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      disabled: _ember.default.computed.not('model.isHTTP')
    }),
    'parameters.path': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('format', {
        regex: /\/.*/,
        message: 'Path must start with /'
      })],
      disabled: _ember.default.computed.not('model.isHTTP')
    },
    'parameters.port': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      disabled: _ember.default.computed('model.type', function () {
        return !this.get('model.isHTTP') && !this.get('model.isTCP');
      })
    })
  });

  exports.default = _model.default.extend(Validations, {
    endpoint: (0, _relationships.belongsTo)('endpoint'),
    type: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return "manual";
      }
    }),
    status: (0, _attr.default)(),
    token: (0, _attr.default)(),
    parameters: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    result: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    expired: (0, _attr.default)('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),

    isHTTP: _ember.default.computed.equal('type', 'http'),
    isTCP: _ember.default.computed.equal('type', 'tcp'),
    isDNS: _ember.default.computed.equal('type', 'dns'),

    isStatusOK: _ember.default.computed.equal('status', 'OK'),
    isStatusKO: _ember.default.computed.equal('status', 'KO')
  });
});