define('heimdall-admin/controllers/services/new', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({
    actions: {
      saveService: function saveService(newService) {
        var _this = this;

        newService.save().then(function () {
          return _this.transitionToRoute('services.show', newService.get('id'));
        });
      }
    }
  });
});