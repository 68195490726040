define('heimdall-admin/controllers/services/show', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({
    actions: {
      deleteService: function deleteService(service) {
        var _this = this;

        var confirmation = confirm('Are you sure you want to delete "' + service.get('name') + '"?');

        if (confirmation) {
          service.destroyRecord().then(function () {
            _this.transitionToRoute('services');
          });
        }
      }
    }
  });
});